import React, { Component } from 'react';

import Marketing from './marketing';
import './index.scss';

class LandingPage extends Component {
    render() {
        return <Marketing location={this.props.location} />;
    }
}

export default LandingPage;
